<template>
<v-container class="mt-10">
  <div>
    <div class="text-h5 font-weight-bold mb-2">Recuerda que...</div>
  <remember></remember>
  </div>
  <div class="mt-10">
    <v-btn class="orange white--text mb-3" depressed x-large block tile @click="goToDetails">
      Continuar con reserva
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <v-btn x-large block outlined depressed tile > Cancelar</v-btn>
  </div>
</v-container>

</template>

<script>
import Remember from "@/components/Remember";
export default {
name: "RemeberPage",
  components: {Remember},
  methods:{
    goToDetails(){
      this.$router.push({name:'MobileDetails'})
    }
  },
  mounted() {
        this.$store.commit('setLoading', false)

  }
}
</script>

<style scoped>

</style>